import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/cartography-view/",
	},
	{
		path: "/cartography-view/",
		name: "CartographyView",
		component: () => import("~/views/CartographyView.vue"),
	},
	{
		path: "/study-view/:id",
		name: "StudyView",
		component: () => import("~/views/StudyView.vue"),
		props: true,
	},
	{
		path: "/contact-view/",
		name: "ContactView",
		component: () => import("~/views/ContactView.vue"),
	},
	{
		path: "/therms-of-use/",
		name: "ThermsOfUseView",
		component: () => import("~/views/ThermsOfUseView.vue"),
	},
	{
		path: "/privacy-policy/",
		name: "PrivacyPolicy",
		component: () => import("~/views/PrivacyPolicy.vue"),
	},
	{
		path: "/maintenance/",
		name: "MaintenanceView",
		component: () => import("~/views/MaintenanceView.vue"),
		meta: { isMaintenance: true },
	},
	{
		path: "/test/",
		name: "testView",
		component: () => import("~/views/TestView.vue"),
	},
	{
		path: "/test2/",
		name: "Test2View",
		component: () => import("~/views/Test2View.vue"),
	},
	{
		// Page 404
		path: "/:pathMatch(.*)*",
		name: "NotFound",
		component: () => import("~/views/404View.vue"),
	},
];
export const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const isMaintenanceMode = import.meta.env.VITE_APP_MODE === "maintenance";
	const isGoingToMaintenanceRoute = to.meta.isMaintenance;

	if (isMaintenanceMode && !isGoingToMaintenanceRoute) {
		next({ name: "MaintenanceView" });
	} else if (!isMaintenanceMode && isGoingToMaintenanceRoute) {
		next({ name: "CartographyView" });
	} else {
		next();
	}
});
